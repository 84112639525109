import { store } from 'store/Store';
import * as RouteAction from 'store/action/RouteAction';

//const clear = () => store.dispatch(RouteAction.clear());
export const get = () => store.getState().route;
export const set = (data) => store.dispatch(RouteAction.set(data));
//const update = (data) => store.dispatch(RouteAction.merge(data));

export const getUrlParam = (param) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    const params = {};
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (typeof param !== 'undefined') {
            if (pair[0] === param) {
                return pair[1];
            }
        } else {
            params[pair[0]] = pair[1];
        }
    }
    return params;
};

export const setMeta = (data) => {
    data.og = {};
    if (typeof data.title !== 'undefined') {
        data.og.title = data.title;
    }
    if (typeof data.description !== 'undefined') {
        data.og.description = data.description;
    }
    return set(data);
};

export const setMetaByRef = (key) => {
    return fetch('./meta.json')
        .then((res) => res.json())
        .then((data) => (typeof data[key] !== 'undefined' ? setMeta(data[key]) : ''));
};

export const url = (key) => {
    return typeof urls[key] !== 'undefined' ? urls[key] : '';
};

const urls = {
    about: '/about-us',
    blog: 'https://blog.propertystudios.co.za',
    contact: '/contact-us',
    dev: '/builders-developers',
    ea: '/estate-agents-solicitors',
    home: '/',
    matrix: 'https://matrix.propertystudios.co.uk',
    privacy: '/privacy-policy',
    terms: '/terms-of-use',

    eaPhoto: '/estate-agents-solicitors#photography',
    eaFloorplan: '/estate-agents-solicitors#floorplan',
    eaBrochure: '/estate-agents-solicitors#brochure',
    eaVs: '/estate-agents-solicitors#virtual',
    eaDrone: '/estate-agents-solicitors#drone',
    eaMicrosite: '/estate-agents-solicitors#microsite',
    eaCgi: '/estate-agents-solicitors#cgi',
    eaCopy: '/estate-agents-solicitors#copywriting',
    eaMarketing: '/estate-agents-solicitors#marketing',
    eaWalkthrough: '/estate-agents-solicitors#walkthrough-video',
    eaSocial: '/estate-agents-solicitors#social-media',
    eaTour: '/estate-agents-solicitors#virtual-tours',

    devCgi: '/builders-developers#cgi',
    devVs: '/builders-developers#virtual',
    devMap: '/builders-developers#site-map',
    devPhoto: '/builders-developers#photography',
    devBrochure: '/builders-developers#brochure',
    devWalkthrough: '/builders-developers#walkthrough-video',
    devTour: '/builders-developers#virtual-tours',
    devDrone: '/builders-developers#drone',
    devSocial: '/builders-developers#social-media',
    devMicrosite: '/builders-developers#microsite',
    devCopy: '/builders-developers#copywriting',
    devMarketing: '/builders-developers#marketing',
};

// const meta = {
//     '/': {
//         title: 'Property Studios: Creative Marketing Agency, Scotland',
//         description:
//             'Property Studios is a Marketing and Design Agency that aids the the Housing and Building Industry with their Branding and Property Sales Material.',
//         img: 'bg/ea-header-3.jpg',
//     },
//     '/about-us': {
//         title: 'About Us: Property Studios',
//         description:
//             'Details about who Property Studios and our Multinational Team of Creative Professionals who share a Passion For All-Things Property.',
//         img: 'bg/about-header.jpg',
//     },
//     '/contact-us': {
//         title: 'Contact Us: Property Studios',
//         description:
//             'Contact Details and Email Contact Form for Property Studios, the Creative Marketing and Design Agency based in Scotland.',
//         img: 'bg/contact-header.jpg',
//     },
//     '/builders-developers': {
//         title: 'Marketing For Builders & Developers: Property Studios',
//         description:
//             'A Fresh & Innovative Approach with a Complete Hands-Free Targeted Strategy. Our Marketing Solutions Bring Developments to Life & Spark Real Interest.',
//         img: 'bg/dev-header-4.jpg',
//     },
//     '/estate-agents-solicitors': {
//         title: 'Marketing For Estate Agents & Solicitors: Property Studios',
//         description:
//             'Award-Winning Creative Marketing Solutions That Sell Property Faster & For Higher Prices, Giving You a Leading Edge in a Crowded Property Market.',
//         img: 'bg/ea-header-4.jpg',
//     },
//     '/privacy-policy': {
//         title: 'Privacy Policy: Property Studios',
//         description: 'Property Studios Privacy Policy.',
//         img: 'bg/ea-header-3.jpg',
//     },
//     '/terms-of-use': {
//         title: 'Terms of Use: Property Studios',
//         description: 'Property Studios Website Terms of Use Policy.',
//         img: 'bg/ea-header-3.jpg',
//     },
// };
